<script setup lang="ts">
import { watch } from "vue";
import * as _ from "lodash";

const props = defineProps({
    initialValue: String,
    color: String,
    type: String,
    name: String,
    minSize: Number,
    placeholder: String,
    required: Boolean,
});

const emit = defineEmits(['update', 'keyup']);

const initialValue = ref(props.initialValue !== undefined ? props.initialValue : '');
const minSize = ref(props.minSize !== undefined ? props.minSize : 0);
const required = ref(props.required !== undefined ? props.required : false);
const inputValue = ref(initialValue.value);
const id = _.uniqueId();
watch(inputValue, (value) => {
    emit('update', value);
});

const inputSize = () => {
    let size = inputValue.value.length ;

    if (size < minSize.value) {
        size = minSize.value;
    }

    return size > 0 ? size : 2;
};

const handleKeyUp = ($event) => {
    emit('keyup', $event);
};
</script>

<template>
    <div class="inline-block w-auto code-input">
        <label :for="`code-input-${id}`" class="sr-only">{{ name }}</label>
        <input
            v-model="inputValue"
            class="code-input-field focus:animate-none animate-pulse"
            :class="`${color}`"
            :size="inputSize()"
            spellcheck="false"
            :placeholder="placeholder"
            :type="type"
            :name="name"
            :id="`code-input-${id}`"
            :required="required"
            @keyup="handleKeyUp"
        >
    </div>
</template>