<script setup lang="ts">
import { computed } from "vue";
import { useFrequentTechStore } from "~/stores/frequentTech/frequentTech";
import { useFrequentTechCategoryStore } from "~/stores/frequentTech/frequentTechCategory";
import { useAsyncData } from "#app";

const frequentTechStore = useFrequentTechStore();
const frequentTechCategoryStore = useFrequentTechCategoryStore();

useAsyncData('frequentTechStore', async () => await frequentTechStore.fetchList());
useAsyncData('frequentTechCategoryStore', async () => await frequentTechCategoryStore.fetchList());

const techItems = computed(() => frequentTechStore.list);
const filterType = computed(() => frequentTechStore.filterType);
const formattedFilters = computed(() => frequentTechCategoryStore.formattedItems);
const filterTypes = computed(() => frequentTechCategoryStore.filterItems);
const filterTech = (type) => {
    const nextFilter = filterTypes.value[filterTypes.value.indexOf(type) + 1];
    frequentTechStore.setFilterType(nextFilter ? nextFilter : "all");
};

</script>

<template>
    <div class="container relative items-center lg:space-x-10 lg:flex">
        <h2 class="mb-10 lg:mb-0 lg:-mt-20 tech-title heading-2 dark:text-white">
            Using an ever-growing stack of technologies.
        </h2>

        <pre
            class="absolute hidden -right-4 sm:right-0 top-16 sm:-top-10 lg:top-0 md:-right-20 code-block sm:block"
            aria-hidden="true"
        >
/**
{{ formattedFilters }}
**/
</pre
>

        <div class="relative z-10 flex-shrink-0 tech-grid">
            <div class="mb-8 lg:-ml-10 code-block">const frequentTech =</div>
            <div
                v-if="techItems !== undefined && techItems.length > 0"
                class="grid grid-cols-2 gap-4 mx-auto sm:grid-cols-4 md:grid-cols-5"
            >
                <TechItem
                    v-for="tech in techItems"
                    :key="tech.id"
                    :tech="tech"
                    :visible="filterType === 'all' ? true : tech.type === filterType"
                />
            </div>
            <div v-else class="grid grid-cols-2 gap-4 mx-auto sm:grid-cols-4 md:grid-cols-5">
                <div v-for="index in 15" class="flex flex-col items-center justify-center space-y-6 bg-soft-white-100 dark:bg-gray-900 rounded-2xl w-full sm:w-[135px] h-[150px] transition-opacity duration-250">
                    <div class="flex items-center h-10 sm:h-20">
                        <div class="animate-pulse rounded-full dark:block md:max-w-none w-[60px] h-[60px] bg-soft-white-200 dark:bg-gray-800"></div>
                    </div>
                    <p class="text-center text-black dark:text-white w-[100%]"><span class="animate-pulse inline-block h-6 w-[80%] rounded-md bg-soft-white-200 dark:bg-gray-800 "></span></p>
                </div>
            </div>

            <div class="flex items-end justify-between mt-6">
                <p class="lg:ml-12 code-block" aria-hidden="true">
                    <span class="text-4xl">...</span>
                </p>

                <p class="code-block">
                    frequentTech.filter("
                    <button
                        @click="filterTech(filterType)"
                        class="button button-code"
                        type="button"
                        aria-label="Filter tech"
                    >
                        {{ filterType }}
                    </button
                    >
                    ")
                </p>
            </div>
        </div>
    </div>
</template>

<style scoped lang="postcss">
.tech-title {
    max-width: 400px;
}

.tech-grid {

@media (max-width: 1024px) {
    max-width:

885px

;
}

}
</style>
