import { defineStore } from 'pinia';
import { FrequentTechCollectionType } from "~/types/frequentTech/FrequentTechCollectionType";
import { useNuxtApp } from "#app";

export const useFrequentTechStore = defineStore({
    id: 'frequentTech',
    state: (): FrequentTechCollectionType => ({
        list: undefined,
        filterType: 'all',
        error: undefined,
    }),
    getters: {
        filteredTechItems(state: FrequentTechCollectionType) {
            return state.filterType === "all" || state?.list === undefined
                ? state.list
                : state.list?.filter(tech => tech.type === state.filterType);
        }
    },
    actions: {
        async fetchList() {
            const config = useRuntimeConfig();

            try {
                const fields = ['title', 'icon_width'];
                const populate = ['icon_dark', 'icon_light', 'frequent_tech_category'];
                const sort = 'sorting:desc';
                const response = await useNuxtApp().axios.get('/frequent-teches', {
                    params: {
                        fields,
                        populate,
                        sort,
                    }
                });
                if (response?.data?.data === undefined || !response?.data?.data.length) {
                    return;
                }

                this.list = response?.data?.data.map(item => ({
                    id: item?.id,
                    label: item?.attributes?.title,
                    svg: config.public.mediaBaseUrl + item?.attributes?.icon_dark?.data?.attributes?.url,
                    svgLight: config.public.mediaBaseUrl + item?.attributes?.icon_light?.data?.attributes?.url,
                    svgWidth: item?.attributes?.icon_width,
                    type: item?.attributes?.frequent_tech_category?.data?.attributes?.title,
                }));
            } catch (error) {
                console.log(error);
                this.error = error.message;
            }

            return true;
        },

        setFilterType(type: string) {
            this.filterType = type;
        }
    },
});
