<script setup lang="ts">
import { computed } from "vue";
import { useAsyncData } from "#app";
import { useTaskStore } from "~/stores/task";

const taskStore = useTaskStore();

await useAsyncData('taskStore', async () => await taskStore.fetchList());
const currentSort = ref('random');
const tasks = computed(() => taskStore.formattedList);
const sortedTasks = computed(() => tasks.value)
const changeSort = (type) => {
    switch (type) {
        case 'random':
            tasks.value = tasks.value.sort((a,b) => a.length - b.length);
            currentSort.value = 'length';
            break;

        case 'length':
            tasks.value = shuffle(tasks.value);
            currentSort.value = 'random';
            break;

        default:
            break;
    }
};

const shuffle = (a) => {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
};
</script>

<template>
    <div class="container relative space-y-8">
        <p class="code-block">
            // Tasks I've helped undertake and launch<br/>

        </p>
        <p class="code-block" aria-hidden="true">
            const tasks =
        </p>

        <ul v-if="sortedTasks" class="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
            <li v-for="(task, index) in sortedTasks" :key="index" class="text-2xl font-medium dark:text-white">
                <p>{{ task }}</p>
            </li>
            <li class="text-2xl text-soft-white-500 dark:text-gray-700">
                <a href="#contact" class="button button-code">Let's build something awesome together</a>
            </li>
        </ul>

        <p class="code-block">
            tasks.sort("
            <button @click="changeSort(currentSort)" class="button button-code" type="button" aria-label="Sort tasks">
                {{ currentSort }}
            </button>
            ")
        </p>

        <p class="absolute right-0 text-4xl -bottom-10 text-lightula-pink dark:text-darkula-pink">===</p>
    </div>
</template>