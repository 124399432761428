import { defineStore } from 'pinia';
import { useNuxtApp } from "#app";
import { TaskCollectionType } from "~/types/task/TaskCollectionType";

export const useTaskStore = defineStore({
    id: 'task',
    state: (): TaskCollectionType => ({
        list: undefined,
        error: undefined,
    }),
    getters: {
        formattedList(state: TaskCollectionType) {
            if (state.list === undefined) {
                return [];
            }

            return state.list.map(item => item.title);
        }
    },
    actions: {
        async fetchList() {
            try {
                const fields = ['title'];
                const response = await useNuxtApp().axios.get('/tasks', {
                    params: {
                        fields,
                    }
                });
                if (response?.data?.data === undefined || !response?.data?.data.length) {
                    return;
                }

                this.list = response?.data?.data.map(item => ({
                    id: item?.id,
                    title: item?.attributes?.title,
                }));
            } catch (error) {
                console.log(error);
                this.error = error.message;
            }

            return true;
        }
    },
});
