<script setup lang="ts">
import { useAsyncData, useNuxtApp } from "#app";
import { setMeta } from "~/utils/meta";

const {data} = await useAsyncData('meta', async () => {
    const response = await useNuxtApp().axios.get('/home-page?populate[seo][populate][]=metaImage');
    return response?.data.data;
});

if (data.value?.attributes !== undefined) {
    setMeta(data.value?.attributes.seo);
}
</script>

<template>
    <div class="my-12 space-y-32 md:my-20 lg:space-y-64">
        <Intro/>

        <Tech/>

        <Stats/>

        <Tasks/>

        <SocialAndContact/>
    </div>
</template>