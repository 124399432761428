<template>
    <div class="container">
        <div class="grid max-w-screen-lg gap-4 mx-auto space-y-10 sm:grid-cols-2 sm:space-y-0">

            <div class="space-y-4">
                <p class="code-block">// Messengers</p>
                <ul class="space-y-4 text-4xl font-medium dark:text-white">
                    <li><a class="link" href="https://t.me/igovet" target="_blank"
                           rel="noreferrer">telegram</a></li>
                    <li><a class="link" href="https://wa.me/995593117248" target="_blank"
                           rel="noreferrer">whatsapp</a></li>
                </ul>
            </div>

            <client-only placeholder="Loading...">
                <ContactForm class="md:pt-16"/>
            </client-only>

        </div>
    </div>
</template>