<script setup lang="ts">
import { useNuxtApp } from "#app";
import * as _ from "lodash";

const email = ref('');
const message = ref('');
const error = ref('');
const success = ref(false);
const inputsKey = ref(_.uniqueId());

const send = async (event) => {
    event.preventDefault();

    try {
        const response = await useNuxtApp().axios.post('/contact-forms', {
            data: {
                email: email.value,
                message: message.value,
            }
        });
        if (response?.data?.data === undefined) {
            error.value = 'Unknown error.';
            success.value = false;
            return;
        }

        success.value = true;
        error.value = '';
        email.value = '';
        message.value = '';
        inputsKey.value = _.uniqueId();
    } catch (e) {
        console.log(e);
        success.value = false;

        if (e?.message) {
            error.value = e.message;
        } else if (e?.error?.message) {
            error.value = e.error.message;
        }
    }
}
</script>

<template>
    <div id="contact">
        <form name="contact" netlify class="space-y-8 code-block" @submit="send" autocomplete="off">
            <p class="code-block">// Get in touch</p>
            <pre>const <span class="text-lightula-green dark:text-darkula-green">form</span> = {
  <label>email:   "<CodeInput @update="email = $event" :initial-value="email" :key="inputsKey" type="email" placeholder="Enter your email" :required="true" min-size="20"/>"</label>
  <label>message: "<CodeInput @update="message = $event" :initial-value="message" :key="inputsKey" type="text" placeholder="Enter your message" :required="true" min-size="20"/>"</label>
}</pre>

            <button type="submit" class="button button-function" aria-label="Submit contact form">
                sendEmail<span class="text-lightula-blue dark:text-darkula-blue">(<span
                class="text-lightula-green dark:text-darkula-green">form</span>)</span>
            </button>
        </form>
        <pre v-if="success" class="code-block mt-8">
showSuccess("<span class="text-lightula-green dark:text-darkula-green">Your message has been sent</span>")
</pre>
        <pre v-if="error" class="code-block mt-8">
showError("<span class="text-red-500">Error: {{ error }}</span>")
</pre>
    </div>
</template>